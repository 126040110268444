import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { renderDefaultCell } from './defaultCells';

export const renderReportLink = (
  value,
  affiliate,
  renderCell = () => {},
  setCrumbsAndFilters = () => {},
  callback = () => {},
  IconComp = null,
  tooltip = '',
  anotherValue = null
) =>
  renderDefaultCell(
    <Tooltip title={tooltip} placement="bottom">
      <div
        data-testid={value}
        className="redirectButton"
        style={{
          display: 'inline-flex',
          alignItems: 'center'
        }}
        onClick={() => {
          setCrumbsAndFilters(affiliate);
          callback(affiliate);
        }}
      >
        {renderCell(value)}
        {IconComp ? (
          <IconComp style={{ paddingLeft: '5px', fontSize: '18px' }} />
        ) : (
          <ChevronRight style={{ paddingLeft: '5px' }} />
        )}
      </div>
    </Tooltip>,
    anotherValue
  );

export const renderReportLinkWithHourly = (
  value,
  affiliate,
  renderCell = () => {},
  setCrumbsAndFilters = () => {},
  callback = () => {},
  IconComp = null,
  callbackIcon = () => {}
) =>
  renderDefaultCell(
    <div>
      <div
        className="redirectButton"
        style={{
          display: 'flex',
          float: 'left',
          alignItems: 'center'
        }}
        onClick={() => {
          setCrumbsAndFilters(affiliate);
          callback(affiliate);
        }}
      >
        {renderCell(value)}
      </div>
      <div
        className="redirectButton"
        style={{
          display: 'flex',
          float: 'left',
          alignItems: 'center'
        }}
      >
        {IconComp ? (
          <IconComp
            style={{ paddingLeft: '5px', fontSize: '18px' }}
            onClick={() => {
              callbackIcon(affiliate);
            }}
          />
        ) : (
          <ChevronRight style={{ paddingLeft: '5px' }} />
        )}
      </div>
    </div>
  );

export const renderLink = (
  value,
  url,
  renderCell = () => {},
  IconComp = null,
  tooltip = '',
  anotherValue = null
) =>
  renderDefaultCell(
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Tooltip
        title={url === '' || !url ? 'Link unavailable' : tooltip}
        placement="bottom"
      >
        <div
          className="redirectButton"
          style={{
            display: 'inline-flex',
            alignItems: 'center'
          }}
          onClick={() => {
            if (url !== '' && url) {
              window.open(url, '_blank').focus();
            }
          }}
        >
          {renderCell(value)}
          {IconComp ? (
            <IconComp style={{ paddingLeft: '5px', fontSize: '18px' }} />
          ) : (
            <ChevronRight style={{ paddingLeft: '5px' }} />
          )}
        </div>
      </Tooltip>
      {anotherValue}
    </div>
  );
