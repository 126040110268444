import { siteOptions, getTrafficSources } from '../../../common/commonRequests';
import {
  DEVICES,
  FORM_DATA_OPTIONS,
  YES_NO_OPTIONS
} from '../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  device: {
    name: 'device',
    type: 'select',
    label: 'Device',
    options: () => DEVICES
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  },
  partial: {
    name: 'partial',
    type: 'select',
    label: 'Form Type',
    options: () => FORM_DATA_OPTIONS
  },
  show_remarketing: {
    name: 'show_remarketing',
    type: 'select',
    label: 'Show Remarketing',
    options: () => YES_NO_OPTIONS
  }
});
