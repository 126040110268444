export const returnIndexOfColumn = (array, columnName) => {
  let index;
  array.forEach(({ name }, i) => {
    if (name === columnName) {
      index = i;
    }
  });
  return index;
};

export const removeIndexOfColumn = (array, columnName) => {
  const index = returnIndexOfColumn(array, columnName);
  if (index) {
    array.splice(index, 1);
  }
  return array;
};
