import { type FC, memo } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer,
  type XAxisProps,
  Legend
} from 'recharts';
import { type IBarDataItem, type IBarChartComponent } from './types';
import { CircularProgress } from '../../Utilities/CircularProgress';
import { isMobileDevice } from '../../common/utilities';
import { useBarUtils } from './utils';

const CustomizedAxisTick: XAxisProps['tick'] = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const BarChartComponent: FC<IBarChartComponent> = memo((props) => {
  const {
    barChatData,
    xAxisProps,
    yAxisProps,
    tooltipFormatter,
    barTooltipFormatter
  } = useBarUtils(props);

  const { loading, data, bars, width: propsWidth, height: propsHeight } = props;

  const isMobile = isMobileDevice();

  if (loading) {
    return CircularProgress(45, {});
  }

  const minMobileWidth = window.innerWidth - 50;
  let mobileWidth = minMobileWidth;

  if (barChatData.length > 4) {
    mobileWidth = Math.ceil(barChatData.length / 4) * minMobileWidth;
  }

  const width = isMobile ? mobileWidth : propsWidth || 650;
  const height = propsHeight || 450;

  return (
    <ResponsiveContainer
      className="responsiveContainer"
      width={width}
      height={height}
    >
      <BarChart
        data={barChatData}
        margin={{ top: 5, right: 30, left: 20, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxisProps} tick={CustomizedAxisTick} />
        <YAxis {...yAxisProps} />
        <Tooltip formatter={tooltipFormatter} />
        {bars.map(({ dataKey, label, color, negativeColor }) => {
          return (
            <Bar dataKey={dataKey} key={dataKey} name={label} fill={color}>
              {data.map((entry, index) => {
                const key = `cell-${index}-${dataKey}`;
                const cellFill =
                  negativeColor &&
                  parseFloat(`${entry?.[dataKey as keyof IBarDataItem]}`) < 0
                    ? negativeColor
                    : color;

                return <Cell key={key} fill={cellFill} />;
              })}
              <Tooltip formatter={barTooltipFormatter} />
              <Legend wrapperStyle={{ bottom: '-10px' }} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
});

export default BarChartComponent;
