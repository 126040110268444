import { tss } from 'tss-react/mui';

export const usePageContainerStyles = tss.create(
  ({ theme: { breakpoints } }) => ({
    container: {
      overflow: 'hidden',
      height: '100%',
      [breakpoints.down('md')]: {
        minHeight: '65vh',
        overflow: 'visible'
      }
    },
    contentContainer: {
      height: '100%',
      maxHeight: '100%',
      padding: '0 10px',
      overflow: 'auto'
    },
    dateRangeContainer: {
      textAlign: 'right'
    },
    toggleButton: {
      paddingLeft: '1rem',
      [breakpoints.down('sm')]: {
        paddingLeft: '0rem',
        display: 'flex',
        alignItems: 'center'
      }
    },
    pageLinkContainer: {
      textAlign: 'right',
      paddingRight: '1rem'
    },
    filterContainer: {
      paddingTop: '5px'
    },
    itemsContainer: {
      paddingTop: '10px',
      marginBottom: '-10px'
    },
    iconDisplayName: {
      display: 'inline-block',
      marginLeft: '1rem',
      paddingTop: '3px',
      [breakpoints.down('sm')]: {
        marginLeft: '0rem'
      }
    },
    actionItems: {
      justifyContent: 'flex-end',
      textAlign: 'right'
    }
  })
);
