/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
  Grid,
  Select as SelectField,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { InfoOutlined } from '@mui/icons-material';
import { CircularProgress } from '../../Utilities/CircularProgress';
import { handleChange as updateFilters } from '../../Filters/actions/actions';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  margin: {
    marginRight: '1rem'
  },
  loader: {
    textAlign: 'center'
  },
  helper: {
    color: 'red'
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center'
  }
});

class Select extends Component {
  state = {
    labelWidth: 0,
    options: [],
    loading: false
  };

  componentDidMount() {
    this.setState((state) => ({
      labelWidth:
        ReactDOM.findDOMNode(this.InputLabelRef)?.offsetWidth ??
        state.labelWidth
    }));
    this.getOptions(this.props, true);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      dependency,
      options,
      secondDependency,
      startDate,
      endDate,
      dependencyName,
      secondDependencyName
    } = this.props;

    const dependencyChanged =
      !!dependency && dependency !== nextProps.dependency;

    const secondDependencyChanged =
      !!secondDependency && secondDependency !== nextProps.secondDependency;

    const optionsChanged =
      Array.isArray(options) && !isEqual(options, nextProps.options);

    const dateIsDependency = [dependencyName, secondDependencyName].includes(
      'date'
    );
    const dateIsChanged = !(
      startDate.isSame(nextProps.startDate) && endDate.isSame(nextProps.endDate)
    );

    const dateDependencyIsChanged = dateIsDependency && dateIsChanged;

    if (
      dependencyChanged ||
      secondDependencyChanged ||
      optionsChanged ||
      dateDependencyIsChanged
    ) {
      this.getOptions(nextProps);
    }
  }

  getOptions = async (props, fromMount = false) => {
    if (typeof props.options === 'function') {
      this.setState({ loading: true });
      const options = props.withinDateRange
        ? await props.options(
            moment(props.startDate).format('YYYY-MM-DD'),
            moment(props.endDate).format('YYYY-MM-DD'),
            props.dateRange,
            props.dependency || false
          )
        : await props.options(
            props.dependency || false,
            props.secondDependency || false
          );
      if (fromMount) {
        this.state.loading &&
          this.setState({ loading: false, options: [...options] });
      } else {
        this.setState({ loading: false, options: [...options] });
      }
      if (props.dependencyName === 'site' && props.name === 'formID') {
        this.props.updateFilters('formID', options[1]?.display);
      }
      if (props.name === 'sellerState') {
        const lookbackDaysKey = Object.fromEntries(
          options.map(({ value, lookbackDaysDefault }) => [
            value,
            lookbackDaysDefault
          ])
        );
        if (!fromMount) {
          this.props.updateFilters('sellerState', options[1]?.value);
        }
        this.props.updateFilters('sellerLookbackDefault', lookbackDaysKey);
      }
    } else {
      this.setState({ options: [...props.options], loading: false });
    }
  };

  renderLabel = () => {
    return this.props.tooltipMessage ? (
      <div className={this.props.classes.tooltip}>
        {this.props.label}
        <Tooltip title={this.props.tooltipMessage} placement="bottom">
          <InfoOutlined style={{ height: '.7em' }} />
        </Tooltip>
      </div>
    ) : (
      this.props.label
    );
  };

  renderInputLabel = () => (
    <InputLabel
      ref={(ref) => {
        this.InputLabelRef = ref;
      }}
      id={`${this.props.label}-label`}
      htmlFor={this.props.name}
      style={this.props.tooltipMessage && { pointerEvents: 'auto' }}
    >
      {this.renderLabel()}
    </InputLabel>
  );

  renderSelectField = (classes) => (
    <SelectField
      variant="outlined"
      label={this.renderLabel()}
      fullwidth={this.props.fullwidth}
      value={this.props.value}
      name={this.props.name}
      error={this.props.error}
      native
      multiple={this.props.multiple}
      id={this.props.name}
      labelId={`${this.props.label}-label`}
      style={{ width: '100%' }}
      className={classNames(classes.margin, classes.textField)}
      onChange={({ target: { value } }) =>
        this.props.onChange(this.props.name, value)
      }
    >
      {this.state.options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={
            (option.display === 'Select a Vertical' && !option.value) ||
            option.disabled
          }
        >
          {option.display}
        </option>
      ))}
    </SelectField>
  );

  renderInput = (classes) => (
    <FormControl variant="outlined" className={classes.formControl}>
      {this.renderInputLabel()}
      {this.renderSelectField(classes)}
      {this.props.message && !this.props.tooltipMessage && (
        <FormHelperText className={classes.helper}>
          {this.props.message}
        </FormHelperText>
      )}
    </FormControl>
  );

  render() {
    const { classes, name } = this.props;
    return (
      <Grid key={name} container justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.margin}>
          {this.state.loading || !!this.props.loading
            ? CircularProgress(40, classes)
            : this.renderInput(classes)}
        </Grid>
      </Grid>
    );
  }
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.func.isRequired
  ])
};

Select.defaultProps = {
  label: '',
  fullwidth: 'false',
  error: false
};

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    selectedPage: state.pageReducer.selectedPage,
    seller_id: state.filters.seller
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateFilters
      },
      dispatch
    )
)(withStyles(Select, styles));
